import { Global, css } from '@emotion/react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

import '@fontsource/nunito'
import '@fontsource/nunito/300.css'
import '@fontsource/nunito/700.css'

import 'react-responsive-modal/styles.css'

const customStyles = css`
  body,
  html,
  #root {
    ${tw`h-full w-full overflow-x-hidden`}
    scroll-behavior: smooth;
  }
  ,
  body {
    ${tw`font-sans text-gray-800 bg-green-50`}
  }
  ,
  h1,
  h2,
  h3 {
    ${tw`font-heading font-bold text-primary`}
  }
  ,
  a {
    ${tw`text-primary`}
  }
  ,
  [type='checkbox']:checked {
    ${css`
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    `}
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
