import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FB_API,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT,
  storageBucket: process.env.REACT_APP_FB_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_SENDER,
  appId: process.env.REACT_APP_FB_APP,
  databaseURL: process.env.REACT_APP_FB_DB_URL,
})

if (process.env.REACT_APP_ENV === 'development') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true // eslint-disable-line no-restricted-globals
}

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LfNImYiAAAAAGSdm_NNgFLdap2EHVUO67TU2V4h'),
  isTokenAutoRefreshEnabled: true,
})

const auth = getAuth(firebaseApp)
const db = getDatabase()

if (process.env.REACT_APP_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099')
}

export { auth, db }
