import { useState, useContext, createContext } from 'react'
import { signInWithEmailLink, signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { onValue, ref } from 'firebase/database'
import normalizeEmail from 'validator/lib/normalizeEmail'

import { useLocalStorage } from './useLocalStorage'

import axios from '../axios'
import { auth, db } from '../firebase'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [claims, setClaims] = useState({})
  const [, setEmail] = useLocalStorage('fj-email', '')
  const [user, loading, error] = useAuthState(auth, {
    onUserChanged: async user => {
      if (user) {
        await user.getIdToken()
        const idTokenResult = await user.getIdTokenResult()
        const customClaims = idTokenResult.claims

        if (claims === customClaims) {
          setClaims(customClaims)
        } else {
          const metadataRef = ref(db, 'metadata/' + user.uid + '/refreshTime')

          onValue(metadataRef, async snapshot => {
            await user.getIdToken(true)
            const idTokenResult = await user.getIdTokenResult()
            const customClaims = idTokenResult.claims

            setClaims(customClaims)
          })
        }
      } else {
        setClaims(null)
      }
    },
  })

  const sendSignInLink = async (email, url) => {
    try {
      await axios.post('/confirm', {
        email,
        url: url || process.env.REACT_APP_FB_CONFIRM_URL,
      })

      setEmail(email)

      return true
    } catch (error) {
      return false
    }
  }

  const signInWithLink = async ({ code, email }) => {
    try {
      await signInWithEmailLink(auth, normalizeEmail(email), code)

      localStorage.removeItem('fj-email')

      return true
    } catch (error) {
      return false
    }
  }

  const logout = () => {
    signOut(auth).then(() => {
      setClaims(null)
    })
  }

  const values = {
    user,
    uuid: (claims || {}).uuid,
    plus: (claims || {}).plus,
    loading,
    error,
    sendSignInLink,
    signInWithLink,
    logout,
  }

  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
