/** @jsxImportSource @emotion/react */

import tw from 'twin.macro'

const Offline = ({ children, title }) => (
  <div tw="min-h-full flex flex-col justify-center p-8 bg-secondary">
    <div tw="mx-auto w-full max-w-md">
      <img
        tw="mx-auto h-16 w-auto drop-shadow-sm"
        src="/favicon.svg"
        alt="FreshJane"
      />
      <h1 css={[tw`mt-6 text-center text-3xl text-green-50`]}>
        We'll be back soon...
      </h1>
    </div>
  </div>
)

export default Offline
