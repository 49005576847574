import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import Offline from './components/Offline'
import GlobalStyles from './styles/GlobalStyles'

import { AuthProvider } from './hooks/useAuth'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <AuthProvider>
      <Router>
        <Offline />
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
